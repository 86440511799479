@font-face {
    font-family: 'Apron';
    src: url('ApronLight-Italic.eot');
    src: local('Apron Light Italic'), local('ApronLight-Italic'),
        url('ApronLight-Italic.eot?#iefix') format('embedded-opentype'),
        url('ApronLight-Italic.woff2') format('woff2'),
        url('ApronLight-Italic.woff') format('woff'),
        url('ApronLight-Italic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Apron';
    src: url('ApronMedium-Italic.eot');
    src: local('Apron Medium Italic'), local('ApronMedium-Italic'),
        url('ApronMedium-Italic.eot?#iefix') format('embedded-opentype'),
        url('ApronMedium-Italic.woff2') format('woff2'),
        url('ApronMedium-Italic.woff') format('woff'),
        url('ApronMedium-Italic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Apron';
    src: url('Apron-Bold.eot');
    src: local('Apron Bold'), local('Apron-Bold'),
        url('Apron-Bold.eot?#iefix') format('embedded-opentype'),
        url('Apron-Bold.woff2') format('woff2'),
        url('Apron-Bold.woff') format('woff'),
        url('Apron-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Apron';
    src: url('Apron-Light.eot');
    src: local('Apron Light'), local('Apron-Light'),
        url('Apron-Light.eot?#iefix') format('embedded-opentype'),
        url('Apron-Light.woff2') format('woff2'),
        url('Apron-Light.woff') format('woff'),
        url('Apron-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Apron Book';
    src: url('ApronBook-Italic.eot');
    src: local('Apron Book Italic'), local('ApronBook-Italic'),
        url('ApronBook-Italic.eot?#iefix') format('embedded-opentype'),
        url('ApronBook-Italic.woff2') format('woff2'),
        url('ApronBook-Italic.woff') format('woff'),
        url('ApronBook-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Apron';
    src: url('ApronBold-Italic.eot');
    src: local('Apron Bold Italic'), local('ApronBold-Italic'),
        url('ApronBold-Italic.eot?#iefix') format('embedded-opentype'),
        url('ApronBold-Italic.woff2') format('woff2'),
        url('ApronBold-Italic.woff') format('woff'),
        url('ApronBold-Italic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Apron Book';
    src: url('Apron-Book.eot');
    src: local('Apron Book'), local('Apron-Book'),
        url('Apron-Book.eot?#iefix') format('embedded-opentype'),
        url('Apron-Book.woff2') format('woff2'),
        url('Apron-Book.woff') format('woff'),
        url('Apron-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Apron';
    src: url('Apron-Heavy.eot');
    src: local('Apron Heavy'), local('Apron-Heavy'),
        url('Apron-Heavy.eot?#iefix') format('embedded-opentype'),
        url('Apron-Heavy.woff2') format('woff2'),
        url('Apron-Heavy.woff') format('woff'),
        url('Apron-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Apron';
    src: url('ApronRegular-Italic.eot');
    src: local('Apron Italic'), local('ApronRegular-Italic'),
        url('ApronRegular-Italic.eot?#iefix') format('embedded-opentype'),
        url('ApronRegular-Italic.woff2') format('woff2'),
        url('ApronRegular-Italic.woff') format('woff'),
        url('ApronRegular-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Apron';
    src: url('ApronHeavy-Italic.eot');
    src: local('Apron Heavy Italic'), local('ApronHeavy-Italic'),
        url('ApronHeavy-Italic.eot?#iefix') format('embedded-opentype'),
        url('ApronHeavy-Italic.woff2') format('woff2'),
        url('ApronHeavy-Italic.woff') format('woff'),
        url('ApronHeavy-Italic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Apron';
    src: url('Apron-Thin.eot');
    src: local('Apron Thin'), local('Apron-Thin'),
        url('Apron-Thin.eot?#iefix') format('embedded-opentype'),
        url('Apron-Thin.woff2') format('woff2'),
        url('Apron-Thin.woff') format('woff'),
        url('Apron-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Apron';
    src: url('ApronThin-Italic.eot');
    src: local('Apron Thin Italic'), local('ApronThin-Italic'),
        url('ApronThin-Italic.eot?#iefix') format('embedded-opentype'),
        url('ApronThin-Italic.woff2') format('woff2'),
        url('ApronThin-Italic.woff') format('woff'),
        url('ApronThin-Italic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Apron';
    src: url('Apron-Medium.eot');
    src: local('Apron Medium'), local('Apron-Medium'),
        url('Apron-Medium.eot?#iefix') format('embedded-opentype'),
        url('Apron-Medium.woff2') format('woff2'),
        url('Apron-Medium.woff') format('woff'),
        url('Apron-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Apron';
    src: url('Apron-Regular.eot');
    src: local('Apron'), local('Apron-Regular'),
        url('Apron-Regular.eot?#iefix') format('embedded-opentype'),
        url('Apron-Regular.woff2') format('woff2'),
        url('Apron-Regular.woff') format('woff'),
        url('Apron-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

